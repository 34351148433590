<template>
  <div>
    <div class="form-control">
      <div class="input-w-icon">
        <input
          type="text"
          name="search"
          v-model="valueSearch"
          placeholder="Buscar"
          @input="search"
        />
        <i v-if="valueSearch.length === 0" class="fas fa-search"> </i>
        <i v-else class="fas fa-times pointer" @click="clearSearch"></i>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-control .input-w-icon i {
  cursor: auto;
}
</style>

<script>
import { ref } from 'vue';

export default {
  emits: ['update:search'],

  setup(_, { emit }) {
    const valueSearch = ref('');

    const search = (event) => {
      const value = event.target.value;
      valueSearch.value = value;
      emit('update:search', value);
    };

    const clearSearch = () => {
      emit('update:search', '');
      valueSearch.value = '';
    };
    return {
      valueSearch,
      search,
      clearSearch,
    };
  },
};
</script>
